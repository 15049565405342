<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>选址项目</span>
        <el-button type="primary" v-show="!isDisabled" @click="onSubmit"
          >保存</el-button
        >
      </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          :model="form"
          label-width="150px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="案卷编号:">
                <el-input disabled v-model="form.ANBH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目代码:">
                <el-input disabled v-model="form.XMDM"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目编号:">
                <el-input :disabled="isDisabled" v-model="form.XMBH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证书编号:">
                <el-input :disabled="isDisabled" v-model="form.ZSBH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="项目名称:">
                <el-input :disabled="isDisabled" v-model="form.XMMC"></el-input>
              </el-form-item>

              <el-form-item label="建设单位名称:">
                <el-input :disabled="isDisabled" v-model="form.JSDWMC"></el-input>
              </el-form-item>
              <el-form-item label="项目依据:">
                <el-input :disabled="isDisabled" v-model="form.XMYJ"></el-input>
              </el-form-item>
              <el-form-item label="用地性质:">
                <el-input :disabled="isDisabled" v-model="form.YDXZ"></el-input>
              </el-form-item>
              <el-form-item label="建设规模:">
                <el-input :disabled="isDisabled" v-model="form.JSGM"></el-input>
              </el-form-item>
              <el-form-item label="工程类型:">
                <el-input :disabled="isDisabled" v-model="form.GCLX"> </el-input>
              </el-form-item>
              <el-form-item label="业务局:">
                <el-input :disabled="isDisabled" v-model="form.YWJ"></el-input>
              </el-form-item>
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="拟选位置:">
                    <el-input :disabled="isDisabled" v-model="form.NXWZ"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="拟用地面积:">
                    <el-input :disabled="isDisabled" v-model="form.NYDMJ">
                      <template #append>平方米</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="登记时间:">
                    <el-input :disabled="isDisabled" v-model="form.DJSJ"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="核发日期:">
                    <el-date-picker :disabled="isDisabled" style="width: 100%" v-model="form.HFRQ" format="YYYY-MM-DD" value-format="YYYY-MM-DD">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="业务年份:">
                    <el-input :disabled="isDisabled" v-model="form.YWNF"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="用地代码:">
                    <el-input :disabled="isDisabled" v-model="form.YDDM"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="容积率上限(%):">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.RJLSX"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="容积率下限(%):">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.RJLXX"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="建筑密度上限(%):">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.JZMDSX"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="建筑密度下限(%):">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.JZMDXX"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="绿地率上限(%):">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.LDLSX"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="绿地率下限(%):">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.LDLXX"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="建筑限高:">
                    <el-input :disabled="isDisabled" v-model="form.JZXG"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="建筑最小控制高度:">
                    <el-input :disabled="isDisabled" v-model="form.JZZXXG"> </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="机动车停车位:">
                    <el-input-number
                      :disabled="isDisabled"
                      v-model="form.JDCTCW"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :step="0.1"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="地面非机动车停车位:">
                    <el-input-number
                          v-model="form.DMFJDCTCW"
                          :disabled="isDisabled"
                          controls-position="right"
                          :min="0"
                          :max="100"
                          :precision="2"
                          :step="0.1"
                        />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="经办人姓名:">
                    <el-input v-model="form.JBRXM" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="经办时间:">
                    <el-date-picker style="width: 100%"  disabled v-model="form.JBSJ">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                
              </el-row>
              <el-form-item label="备注:">
                <el-input :disabled="isDisabled"
                  v-model="form.BZ"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>


<script>
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'xzxm',
  components: { FloatTitBtn },
  data() {
    return {
      labelPosition: "left",
      form: {
        Id: "",
        BJID: "",
        XMBH: "",
        XMDM: "",
        ANBH: "",
        ZSBH: "",
        XMMC: "",
        JSDWMC: "",
        XMYJ: "",
        YDXZ: "",
        NXWZ: "",
        NYDMJ: "",
        JSGM: "",
        YWJ: "",
        HFRQ: "",
        DJSJ: "",
        YWNF: "",
        YDDM: "",
        RJLSX: 0,
        RJLXX: 0,
        JZMDSX: 0,
        JZMDXX: 0,
        LDLSX: 0,
        LDLXX: 0,
        JZXG: 0,
        JZZXXG: 0,
        GCLX: "",
        JDCTCW: 0,
        DMFJDCTCW: 0,
        JBR: "",
        JBRXM: '',
        JBSJ: "",
        QLSJ: "",
        QLRY: "",
        ZJSJ: "",
        ZJRY: "",
        ZJYJ: "",
        BZ: ""
      },
      forms: {
        BJID: "办件ID",
        XMBH: "项目编号",
        XMDM: "项目代码",
        ANBH: "案卷编号",
        ZSBH: "证书编号",
        XMMC: "项目名称",
        JSDWMC: "建设单位名称",
        XMYJ: "项目依据",
        YDXZ: "用地性质",
        NXWZ: "拟选位置",
        NYDMJ: "拟用地面积",
        JSGM: "建设规模",
        YWJ: "业务局",
        HFRQ: "核发日期",
        DJSJ: "登记时间",
        YWNF: "业务年份",
        YDDM: "用地代码",
        RJLSX: "容积率上限",//18
        RJLXX: "容积率下限",
        JZMDSX: "建筑密度上限",
        JZMDXX: "建筑密度下限",
        LDLSX: "绿地率上限",
        LDLXX: "绿地率下限",
        JZXG: "建筑限高",
        JZZXXG: "建筑最小控制高度",//25
        GCLX: "工程类型",
        JDCTCW: "机动车停车位",
        DMFJDCTCW: "地面非机动车停车位",
        // JBR: "经办人",
        JBRXM: "经办人姓名",
        JBSJ: "经办时间",
        QLSJ: "清理时间",
        QLRY: "清理人员",
        ZJSJ: "质检时间",
        ZJRY: "质检人员",
        ZJYJ: "质检意见",
        BZ: "备注"
      },
      isDisabled: true,
    };
  },
  methods: {
    async onSubmit() {
      let me = this
      me.form.JBRXM = me.$router.user.profile.name;
      me.form.JBSJ = new Date();
      await this.$ajax
        .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatexzxm", me.form)
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form.Id = response.data.Data;
            ElMessage({
              type: "success",
              message: "保存成功",
            });
          } else {
            ElMessage({
              type: "info",
              message: "保存失败!",
            });
            console.log(response.data.errorMessage);
          }
        });

        console.log(this.form);
      
    },
    checkform() {

      let data = [];
      let labelList = [];
      for (let k in this.forms) {
        data.push(k);
        labelList.push(this.forms[k])
      }
      let r = -1;
      let checkNum = /^([0-9][0-9]*)+(\.[0-9]{1,2})?$/
      for (let i = 0; i < data.length; i++) {
        let key = data[i]
        if (this.form[key] === '' || this.form[key] === null || this.form[key] === undefined) {
          r = i;
          break;
        } else if (i === 10 || i === 23 || i === 24 || i === 26 || i === 27) {
          let sk = data[i]
          if (!checkNum.test(this.form[sk])) {
            ElMessage({
              type: 'info',
              message: this.forms[sk] + '内容请输入数字',
            })
            return false;
          }
        }
      }
      if (r == -1) {
        return true
      } else {
        ElMessage({
          type: 'info',
          message: '请输入' + labelList[r],
        })
        return false;
      }
    },
    async switch() {
      let me = this;
      me.form.BJID = this.$route.query.instanceId
      await this.$ajax
        .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/getxzxm?BJID=" + me.form.BJID)
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form = response.data.Data;
            if(response.data.Data.Id===null){
              me.form.JBRXM=me.$router.user.profile.name;
              me.form.JBSJ=new Date();
              me.form.QLSJ=null;
              me.form.ZJSJ=null;
              me.getlahz();
            }
            me.form.ANBH = me.$store.state.AJH;
            me.form.XMDM = me.$store.state.XMDM;
          } else {
            ElMessage({
              type: "info",
              message: "初始化表单失败",
            });
          }
        });
        if (this.form.JBR === this.$router.user.profile.sub && this.$route.query.taskId) {
          this.isDisabled = false;
        }
    },
    async getlahz() {
      let me = this;
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/Getlahz?Id=" +
            me.$route.query.instanceId
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              let res = response.data.Data;
              console.log("res值88888888888",res);
              me.form.XMMC = res.XMMC
              me.form.JSDWMC = res.JSDW
            } else {
              console.log(response.data.ErrorMessage);
            }
          });
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
  },
  mounted() {
    this.switch()
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
.el-input-number {
  width: 100%;
}
</style>
